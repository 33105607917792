<template>
  <div>
    <p
      v-for="(error, e) in preparedErrors"
      :key="e"
      class="text-sm text-red-600"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import has from 'lodash/has';
import flatten from 'lodash/flatten';
import isObject from 'lodash/isObject';
import values from 'lodash/values';

export default {
  props: {
    errors: {
      type: [Array, Object],
      default: () => [],
    },
    ignoreValidators: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    preparedErrors() {
      // Vuelidate
      if (has(this.errors, '0.$message')) {
        return this.errors
          .filter(
            (error) =>
              !this.ignoreValidators.some(
                (ignoreValidator) => ignoreValidator === error.$validator,
              ),
          )
          .map((error) => error.$message);
      }

      // Other formats
      return flatten(isObject(this.errors) ? values(this.errors) : this.errors);
    },
  },
};
</script>
